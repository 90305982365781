import React from 'react'

const Header = () => {
  return (
    <div className='flex items-center justify-between max-w-7xl m-auto p-5 text-white'>
        <div className='flex-1'>
            <h1 className='text-3xl font-bold poppins-semibold text-green-400'>Tejj</h1>
        </div>
        <div className='flex-1 hidden md:flex lg:flex'>
            <ul className='flex gap-8 justify-center'>
                <li className='cursor-pointer hover:underline'>Work</li>
                <li className='cursor-pointer hover:underline'>Projects</li>
                <li className='cursor-pointer hover:underline'>Experience</li>
                <li className='cursor-pointer hover:underline'>Contact</li>
            </ul>
        </div>
        <div className='text-black flex-1 hidden sm:flex lg:flex justify-end'>
            <button className=' bg-[#ffffff] p-2 rounded-lg hover:bg-green-400 hover:text-white'>Get in touch 🤙</button>
        </div>
    </div>
  )
}

export default Header