import React, { useRef } from 'react'
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import "./layout.css";


const Hero = () => {

    const divRef = useRef(null);

    gsap.registerPlugin(useGSAP)

    useGSAP(() => {
        const t1 = gsap.timeline();
        t1.fromTo(".hero__heading", { y: +90 }, { y: 0, opacity: 1, duration: 1.2 });
        t1.fromTo(".hero__btn", { y: -40 }, { y: 0, opacity: 1, duration: 1.2 }, "-=0.80");
    }, { scope: divRef })


    return (
        <section id='#hero' className='snap-y snap-mandatory py-36'>
            <div className='flex flex-col justify-center items-centr border-b-sky-50 lg:border-b-[.1px]' ref={divRef} >
                <div className='flex flex-col items-center py-5'>
                    <h1 className='hero__heading text-center text-[60px] sm:text-[98px] lg:text-[144px] font-semibold flex-1 opacity-0'>
                        <span className='text-white'>D</span>evelop
                    </h1>
                    <p className='hero__subHeading_1  text-white overflow-hidden text-centerflex justify-center items-center text-[44px] sm:text-[72px] lg:text-[120px] opacity-1 bg-gray-950 pb-20 z-10'>
                       Design
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Hero