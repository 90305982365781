import React from 'react';
import { BiLinkExternal } from "react-icons/bi";

const Project = (props) => {
  return (
    <div className='bg-slate-800 hover:bg-slate-900 cursor-pointer'>
      <div className='flex flex-col gap-4 items-center justify-center p-5'>
        <div className='flex rounded-full object-contain overflow-hidden h-[120px] w-[120px]' >
          <img className='rounded-full ' src={props.url} />
        </div>
        <div>
          <h2 className='text-gray-50'>
            <li className='list-none flex items-center justify-center gap-2 underline'><a href={props.orgUrl}>{props.org}</a><span><BiLinkExternal /> </span></li>
          </h2>
        </div>
        <div>
          <p className='text-green-400'>{props.role}</p>
        </div>
        <div>
          <p className='text-gray-500'>{props.date}</p>
        </div>
        <div>
          {props.desc.map((item, i) =>
          {
            return <li className='text-gray-200 pb-4'>{item}</li>
          })}
        </div>
      </div>
    </div>
  )
}

export default Project