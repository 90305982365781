import React from 'react'
import Project from '../common/Project'

const Projects = () => {
    return (
        <section className='py-10'>
            <div className='flex flex-col md:flex-row lg:flex-row gap-10 overflow-x-scroll'>
                <div className='flex-1'>
                    <Project
                        url={"https://i.pinimg.com/474x/ee/7f/60/ee7f60e8344d730117347443f7e7efae.jpg"}
                        org="Deloitte"
                        orgUrl='https://www2.deloitte.com/us/en.html'
                        role="Consultant"
                        desc={[
                            "Led the development of micro-services architecture using Node.js, React.js, and Angular.js, resulting in a 30% improvement in application performance.",
                            "Worked on CI/CD pipelines using Azure DevOps, reducing deployment time by 40%. Integrated NoSQL databases, including MongoDB, optimising data storage and retrieval processes.",
                            "Collaborated with DevOps teams to automate infrastructure provisioning and deployment processes.",
                            "Contributed on multiple projects with different technical skills.",
                            "Contributes in the CSR activities as Lead."
                        ]}
                        date="From 21'st April, 2021"
                    />
                </div>
                <div className='flex-1'>
                    <Project
                        url={"https://logos-world.net/wp-content/uploads/2023/03/Cognizant-Logo.jpg"}
                        org="Cognizant"
                        orgUrl='https://www.cognizant.com/in/en'
                        role="Senior Developer"
                        desc={[
                            "Developed and maintained backend components of web applications using Node.js and ReactJs.",
                            "Implemented CI/CD best practices, reducing the time from code commit to deployment by 50%.",
                            "Worked closely with front-end developers to integrate React.js components into the overall application architecture.",
                            "Designed and optimised NoSQL database schemas for improved performance and scalability.",
                            "Designed and developer chatbots for venture project as POCs. Implemented Twilio Cloud SDKs contact centre web application developments.",
                            "Worked on Azure, AWS cloud services, Microservice architectures and third party programmable SDKs integration with NodeJs applications."
                        ]}
                        date="From Feb, 2018 till March, 2021"
                    />
                </div>
            </div>
        </section>
    )
}

export default Projects