import React from 'react';
import { SocialIcon } from 'react-social-icons';

const About = () => {
    return (
        <section id='#hero' className='py-10'>
            <div>
                <h1 className='text-2xl text-green-400'>About me</h1>
            </div>
            <div className='flex py-5 flex-col sm:flex-col md:flex-col lg:flex-row gap-10'>
                <div className='text-white flex-1'>
                    <div className='flex flex-col gap-5 text-gray-400'>
                        <p>Results-driven and versatile <span className='text-white font-semibold'>Nodejs</span> developer with <span className='text-white font-semibold'>6 years</span> of extensive experience in building scalable and performant web applications. Proficient in <span className='text-white font-semibold'>Nodejs</span>, <span className='text-white font-semibold'>ECMA Script</span>, <span className='text-white font-semibold'>JavaScript/TypeScript</span>, <span className='text-white font-semibold'>RestAPI</span>, <span className='text-white font-semibold'>Microservices</span>, <span className='text-white font-semibold'>UI Frameworks (React.js and Angular.js)</span></p>
                        <p>Expertise in <span className='text-white font-semibold'>CI/CD</span>, <span className='text-white font-semibold'>Azure DevOps</span>, and <span className='text-white font-semibold'>NoSQL databases</span> and <span className='text-white font-semibold'> SQL databases</span>. Adept at collaborating with cross-functional teams to deliver high-quality software solutions.</p>
                        <p>Having a solid understanding of <span className='text-white font-semibold'>asynchronous programming</span>, <span className='text-white font-semibold'>event driven architecture</span>, <span className='text-white font-semibold'>cloud environment development</span>.</p>
                        <div className='flex gap-4'>
                            <SocialIcon network='youtube' url='https://www.youtube.com/channel/UC5ENrvBTLWwt_8Q9PzbVkcw'/>
                            <SocialIcon network='linkedin' url='https://www.linkedin.com/in/tejj-jain-8a451b108/' />
                            <SocialIcon network='github' url='https://github.com/Tejj2103'/>
                            {/* <SocialIcon network='twitter' /> */}
                        </div>
                    </div>
                </div>
                <div className='flex-1 flex justify-center items-center'>
                    <img className='object-cover h-[400px] w-full' src='https://images.pexels.com/photos/1181676/pexels-photo-1181676.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />
                </div>
            </div>
        </section>
    )
}

export default About