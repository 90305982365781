import React from 'react'
import Hero from '../components/layout/Hero'
import About from '../components/layout/About'
import Projects from '../components/layout/Projects'

const Home = () => {
  return (
    <div className='max-w-7xl m-auto p-5'>
        <Hero/>
        <About/>
        <Projects/>
    </div>
  )
}

export default Home